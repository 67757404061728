.container {
  height: 100%;
  width: 100%;
}

.container.is-active {
  pointer-events: auto;
}

.unread-count {
  background: rgb(var(--color-theme-accent));
  border-radius: 9px;
  color: var(--color-light-text);
  font-size: 12px;
  font-weight: bold;
  height: 18px;
  line-height: 1.4;
  padding: 0 6px;
  position: absolute;
  left: 95px;
  top: 5px;
  z-index: 3;
}

.content {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  position: relative;
  width: 100%;
}

.title {
  font-family: var(--font-alphapipe);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.loading-spinner {
  padding-top: 18px;
  flex-grow: 1;
}

.conversation-container {
  align-content: end;
  background: rgb(var(--color-theme-button));
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 16px;
  overflow-y: auto;
  padding: 16px;
}

.conversation {
  margin-top: auto;
}

.message {
  font-size: 14px;
  line-height: 1.1;
  margin-bottom: 16px;
}

.message:last-child {
  margin-bottom: 0;
}

.message.is-roll {
  font-style: normal;
}

.message.is-ping {
  padding: 18px 0;
  color: var(--color-alert-red);
  font-weight: bold;
  text-align: center;
  letter-spacing: var(--heading-letter-spacing);
  text-indent: var(--heading-letter-spacing);
}

.author {
  font-weight: bold;
}

.md-body {
}

.md-heading {
  margin-bottom: 5px;
  font-size: 14px;
}

.md-heading + .md-heading {
  margin-top: -5px;
}

.md-body p {
  margin: 0 0 5px;
  padding: 0;
  word-wrap: break-word;
}

.md-body p:last-child {
  margin-bottom: 0;
}

.md-body a {
  color: var(--color-teal);
  text-decoration: none;
}

.md-body a:hover,
.md-body a:focus {
  text-decoration: underline;
}

.md-body ul,
.md-body ol {
  margin: 0 0 5px 0;
  padding: 0 0 0 18px;
}

.md-body ul ul,
.md-body ol ol {
  margin-top: 4px;
}

.md-body ul li,
.md-body ol li {
  margin-bottom: 2px;
}

.md-body blockquote {
  margin: 0 0 5px 0;
  padding: 4px 18px;
  border-left: solid 4px var(--color-text-light-grey);
}

.md-body blockquote p {
  margin: 0;
}

.md-body img {
  max-width: 100%;
  display: block;
  margin: 0;
}

.md-body code {
  margin: 0;
  padding: 2px 4px;
  background: var(--color-light-grey);
  border: 1px solid var(--color-text-light-grey);
  border-radius: 4px;
  white-space: nowrap;
}

.md-body pre {
  margin: 0 0 5px 0;
  padding: 4px 9px;
  background: var(--color-light-grey);
  border: 1px solid var(--color-text-light-grey);
  border-radius: 4px;
  overflow: auto;
}

.md-body pre > code {
  padding: 0;
  background: none;
  border: none;
  white-space: pre;
}

.md-body table {
  width: 100%;
  margin-bottom: 5px;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
}

.md-body thead {
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: var(--heading-letter-spacing);
  text-indent: var(--heading-letter-spacing);
}

.md-body thead tr,
.md-body tr:nth-child(even) {
  background: var(--color-light-grey);
}

.md-body th,
.md-body td {
  padding: 4px 9px;
  border: 1px solid var(--color-text-light-grey);
  word-wrap: break-word;
}

.md-body th {
  font-size: 12px;
}

.form {
  display: block;
  padding: 12px 16px;
  position: relative;
  width: 100%;
}

.form-button {
  position: absolute;
  right: 22px;
  top: 51%;
  transform: translateY(-50%);
}

.message-input {
  background: rgba(0 0 0 / 0.3);
  border: none;
  border-radius: 10px;
  color: var(--color-white);
  display: block;
  font-size: 16px;
  line-height: 1.2;
  min-height: 42px;
  outline: none;
  padding: 10px;
  padding-right: 40px;
  resize: none;
  width: 100%;
}

.message-input:disabled {
  background: rgb(var(--color-theme-button));
  overflow: hidden;
  padding-right: 10px;
  text-align: center;
}

.message-input::placeholder {
  color: rgb(var(--color-theme-text));
}

.actions {
  padding: 7px 9px;
  display: flex;
  align-items: center;
  background: var(--color-white);
  border: 2px solid var(--color-light-grey);
  border-top: 0;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

.spacer {
  flex: 1 1;
}

.select-container {
  padding-left: 16px;
  width: 100%;
}

.select {
  width: 100%;
  height: auto;
  margin-left: 5px;
  display: inline-block;
}

.ping-button {
  border-radius: 20px;
  flex: none;
  height: 24px;
  margin-left: 20px;
  padding: 0;
  width: 24px;
}

.ping-button svg {
  width: 12px;
  height: 12px;
}

.ping-button path {
  fill: var(--color-light-text);
  stroke-opacity: 0;
}

.ping-button-content {
  width: 24px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
